export default [
  {
    path: '/ui/typography',
    name: 'ui-typography',
    component: () => import('@/views/ui/typography/Typography.vue'),
    meta: {
      pageTitle: 'Typography',
      breadcrumb: [
        {
          text: 'UI',
        },
        {
          text: 'Typography',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ui/colors',
    name: 'ui-colors',
    component: () => import('@/views/ui/colors/Colors.vue'),
    meta: {
      pageTitle: 'Colors',
      breadcrumb: [
        {
          text: 'UI',
        },
        {
          text: 'Colors',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ui/icons',
    name: 'ui-icons',
    component: () => import('@/views/ui/icons/Icons.vue'),
    meta: {
      pageTitle: 'Icons',
      breadcrumb: [
        {
          text: 'UI',
        },
        {
          text: 'Icons',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ui/download',
    name: 'download',
    component: () => import('@/views/ui/download/Download.vue'),
    meta: {
      pageTitle: 'Download',
      breadcrumb: [
        {
          text: 'UI',
        },
        {
          text: 'Download',
          active: true,
        },
      ],
    },
  }
]
