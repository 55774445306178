export default [
  {
    path: '/brand-guideline/about',
    name: 'about',
    component: () => import('@/views/brand-guideline/guideline/About.vue'),
    meta: {
      pageTitle: 'Introduction',
      breadcrumb: [
        {
          text: 'guideline',
        },
        {
          text: 'Introduction',
          active: true,
        },
      ],
    },
  },
  {
    path: '/brand-guideline/guideline',
    name: 'guideline',
    component: () => import('@/views/brand-guideline/guideline/Guideline.vue'),
    meta: {
      pageTitle: 'Guideline',
      breadcrumb: [
        {
          text: 'guideline',
        },
        {
          text: 'Guideline',
          active: true,
        },
      ],
    },
  }
]
